<template>
    <div class="pb-5">
        <v-title title="Product Feed Overview"></v-title>

        <title-box>
            <div class="row">
                <div class="col col-9">
                    <h1>Product Feed Overview</h1>
                </div>

                <div class="col col-3 text-right">
                    <mercur-button class="btn btn-raised btn-primary" :to="{ name: 'AddProductFeed' }">
                        + Add new product feed
                    </mercur-button>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">
            <template v-if="hasPermission('listProductFeed')">
                <div class="mt-4">
                    <pagination :pagination="pagination" @change="changeAmountOfItems"></pagination>
                    <merchant-data-table
                        class="shadow"
                        :options="options"
                        :url="url"
                        ref="table"
                        @paginationSet="updatePagination"
                        :isNotApplicationBound="true">
                    </merchant-data-table>
                </div>
            </template>
            <span v-else>Not allowed to see this view</span>
        </div>
        <mercur-dialog :is-open.sync="isDeleteProductFeedConfirmOpen">
            <template #header>
                <h3>Delete product feed</h3>
            </template>
            <p v-if="pendingDeleteProductFeed">
                Are you sure you want to delete product feed '{{pendingDeleteProductFeed.configurationName}}'?
            </p>
            <template #footer>
                <mercur-button class="btn">Cancel</mercur-button>
                <mercur-button class="btn btn-primary" @click="deleteProductFeed(pendingDeleteProductFeed)">Delete</mercur-button>
            </template>
        </mercur-dialog>
    </div>
</template>

<script>
import CONFIG from '@root/config'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Pagination from '@/components/elements/table/Pagination'

export default {
    name: 'ProductFeedOverview',
    components: { MerchantDataTable, Pagination },

    data () {
        return {
            isDeleteProductFeedConfirmOpen: false,
            pendingDeleteProductFeed: {},
            url: CONFIG.API.ROUTES.PRODUCT_FEEDS.OVERVIEW,
            isDialogOpen: false,
            activeData: null,
            pagination: {},
            amountOfDisplayedItems: 50,
            isLoading: false,
            dialog: {
                isOpen: false,
            },
            options: {
                columns: {
                    'Configuration Name': {
                        field: 'configurationName',
                        link: (value, data) => {
                            return {
                                name: 'ProductFeedDetails',
                                params: {
                                    productFeedId: data.productFeedId,
                                },
                            }
                        },
                    },
                    'Country Codes': { field: 'countryCodes', filter: false },
                    'language': { field: 'language', filter: false },
                    'Date Created': { field: 'dateCreated', filter: false },
                    'Date Updated': { field: 'dateUpdated', filter: false, sortable: true },
                },
                actions: {
                    type: 'dropdown',
                    items: [
                        {
                            text: 'Edit',
                            to: ({ data }) => {
                                return {
                                    name: 'EditProductFeed',
                                    params: {
                                        productFeedId: data.productFeedId,
                                    },
                                }
                            },
                            isHidden: () => !this.hasPermission('updateProductFeed'),
                        },
                        {
                            text: 'Delete',
                            onClick: ({ data }) => {
                                this.pendingDeleteProductFeed = data
                                this.isDeleteProductFeedConfirmOpen = true
                            },
                            isHidden: () => !this.hasPermission('removeProductFeed'),
                        },
                    ],
                },
                paginationType: 'custom',
                paginationPageSize: 50,
                cacheBlockSize: 50,
                defaultColDef: { menuTabs: [] },
                sortModel: [
                    {
                        colId: 'dateUpdated',
                        sort: 'desc',
                    },
                ],
            },
        }
    },
    methods: {
        deleteProductFeed (productFeed) {
            const url = CONFIG.API.ROUTES.PRODUCT_FEEDS.DELETE.replace('{productFeedId}', productFeed.productFeedId)
            this.post(url, productFeed, 'Product feed was deleted').then(() => {
                this.isDeleteProductFeedConfirmOpen = false
                this.$refs.table.refreshGrid()
            })
        },
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },

    },
}
</script>

<style lang="scss" scoped>
.c-configurations {
    padding: 50px;
}
</style>
